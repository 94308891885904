import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { useScrollDirection } from '/machinery/useScrollDirection'
import { useBodyScrollLock } from '/machinery/useBodyScrollLock'
import { routeMap } from '/routeMap'
import { useTranslate, useLanguage } from '/machinery/I18n'

import { ButtonLinkBlue } from '/features/buildingBlocks/Button'
import { ImageCover } from '/features/buildingBlocks/Image'
import { Icon } from '/features/buildingBlocks/Icon'
import { Link } from '/features/buildingBlocks/Link'

import logo from '/images/bitvavo-logo.raw.svg'
import graph from '/images/graph.raw.svg'
import triangleUp from '/images/icons/triangle-down.raw.svg'

import styles from './SiteHeader.css'

export function SiteHeaderJob({ menu, layoutClassName }) {
  return (
    <SiteHeaderBase {...{ menu, layoutClassName }} slot={
      menu.graph?.growth && (
        <Graph
          layoutClassName={styles.graph}
          currentEmployees={menu.graph?.employees}
          growth={menu.graph?.growth}
        />
      )}
    />
  )
}

export function SiteHeaderHome({ menu, layoutClassName }) {
  const language = useLanguage()
  const { __ } = useTranslate()
  return (
    <SiteHeaderBase {...{ menu, layoutClassName }} slot={
      <ButtonLinkBlue
        href={routeMap.app.jobs.index({ language })}
        dataX='link-to-jobs'
        layoutClassName={styles.headerButton}
      >
        {__`join-us`}
      </ButtonLinkBlue>}
    />
  )
}

export function SiteHeader({ menu, layoutClassName }) {
  return (
    <SiteHeaderBase {...{ menu, layoutClassName }} />
  )
}

export function SiteHeaderDark({ menu, layoutClassName }) {
  return (
    <SiteHeaderBase dark {...{ menu, layoutClassName }} />
  )
}

function SiteHeaderBase({ menu, slot = undefined, dark = undefined, layoutClassName = undefined }) {
  const [menuActive, setMenuActive] = React.useState(false)
  const [coloredBackground, setColoredBackground] = React.useState(false)
  const scrollDirection = useScrollDirection()
  const hideMenu = scrollDirection === 'down'
  const items = menu.items ?? []
  const { ref: bodyScrollLockRef } = useBodyScrollLock({ enabled: menuActive })

  React.useEffect(
    () => {
      window.addEventListener('scroll', handleScroll)

      return () =>
        window.removeEventListener('scroll', handleScroll)
    }, []
  )

  function handleScroll() {
    if (window.scrollY > 220) {
      setColoredBackground(true)
    } else {
      setColoredBackground(false)
    }
  }

  return (
    <div
      className={cx(
        styles.componentBase,
        hideMenu && styles.hide,
        dark && !menuActive && styles.dark,
        coloredBackground && styles.coloredBackground,
        layoutClassName
      )}
      ref={bodyScrollLockRef}
    >
      <Logo layoutClassName={styles.logo} />
      <MenuDesktop
        layoutClassName={styles.menuDesktop}
        {...{ items }}
      />
      <div className={cx(styles.aside)}>
        <Hamburger
          onMenuActiveChange={() => setMenuActive(x => !x)}
          {...{ menuActive }}
        />
        {slot && (
          <div className={styles.slot}>
            {slot}
          </div>
        )}
      </div>
      <Drawer
        image={menu.mobileMenuBackgroundImage}
        layoutClassName={cx(styles.drawer, menuActive && styles.isActive)}
        {...{ items }}
      />
    </div>
  )
}

function Logo({ layoutClassName }) {
  const language = useLanguage()

  return (
    <div className={cx(styles.componentLogo, layoutClassName)}>
      <a
        href={routeMap.app.home({ language })}
        data-x='link-to-home'
      >
        <Icon icon={logo} />
      </a>
    </div>
  )
}

function MenuDesktop({ layoutClassName, items }) {
  return (
    <div className={cx(styles.componentMenuDesktop, layoutClassName)}>
      <Menu {...{ items }} />
    </div>
  )
}

function Menu({ items, layoutClassName = undefined }) {
  return (
    <ul className={cx(styles.componentMenu, layoutClassName)}>
      {items.map((x, index) => (
        <li key={index}>
          <Link
            dataX='main-menu-link'
            layoutClassName={styles.link}
            href={determineDocumentPathSync({ document: x.link, routeMap })}
          >
            {x.title}
          </Link>
        </li>
      ))}
    </ul>
  )
}

function Graph({ layoutClassName, currentEmployees, growth }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentGraph, layoutClassName)}>
      <span className={styles.employees}>{currentEmployees && ( __({ current: currentEmployees })`employees` )}</span>
      <Icon icon={graph} layoutClassName={styles.graphIcon} />
      {growth && (
        <div className={styles.growth}>
          <Icon icon={triangleUp} layoutClassName={styles.triangle} />
          {growth}%
        </div>
      )}
    </div>
  )
}

function Hamburger({ menuActive, onMenuActiveChange }) {
  return (
    <div className={styles.componentHamburger}>
      <button
        className={cx(styles.button, menuActive && styles.active)}
        onClick={onMenuActiveChange}
        aria-label={'toggle-menu'}
        aria-expanded={menuActive}
        data-x={menuActive ? 'close-menu' : 'open-menu'}
      >
        <span className={cx(styles.hamburgerLines, menuActive && styles.isOpen)}>
          <span className={styles.lineA} />
          <span className={styles.lineB} />
        </span>
      </button>
    </div>
  )
}

function Drawer({ layoutClassName, items, image }) {
  return (
    <div className={cx(styles.componentDrawer, layoutClassName)}>
      <Menu layoutClassName={styles.mobileMenu} {...{ items }} />
      {image?.asset && (
        <ImageCover aspectRatio={9 / 16} layoutClassName={styles.menuImage} {...{ image }} />
      )}
    </div>
  )
}
