export function useScrollDirection() {
  const prevOffsetRef = React.useRef(0)
  const [scrollDirection, setScrollDirection] = React.useState('up')

  React.useEffect(
    () => {
      window.addEventListener('scroll', toggleScrollDirection)
      return () => {
        window.removeEventListener('scroll', toggleScrollDirection)
      }
      function toggleScrollDirection() {
        const scrollY = window.scrollY
        const scrollThreshold = 60

        if (scrollY === 0) {
          setScrollDirection(null)
        }

        if (Math.abs(scrollY - prevOffsetRef.current) < scrollThreshold) {
          return
        }
        setScrollDirection(scrollY > prevOffsetRef.current ? 'down' : 'up')
        prevOffsetRef.current = scrollY
      }
    },
    [scrollDirection]
  )
  return scrollDirection
}
